<template>
  <div class="contrainer minWidth1200">
    <div class="contrainer-left">
      <custom-actions class="card" title="所属基站">
        <div slot="header" >
          <el-button
            v-if="permission.add"
            size="mini"
            type="primary"
            @click="onAdd"
            >新增设备</el-button
          >
        </div>
        <el-tree
          :expand-on-click-node="false"
          :highlight-current="true"
          :props="defaultProps"
          :load="getDept"
          lazy
          @node-click="handleNodeClick"
        >
          <template v-slot="{ data }">
            <div class="org-item">
              <!-- <span>{{ data.deptName }}</span> -->
              <!-- <i v-if="data.deviceDel === '1'" style="color: green;margin-right: 5px;" class="el-icon-circle-check"></i>
              <i v-if="data.deviceDel === '2'" style="color: red;margin-right: 5px;" type="el-icon-circle-close"></i> -->
              <!-- <img class="device" v-if="data.deviceDel === '1'" src="@/assets/kongkai11.svg"/> -->
              <img class="device" v-if="data.deviceDel === '1'" src="@/assets/gaojing_online.png"/>
              <img class="device" v-if="data.deviceDel === '2'" src="@/assets/gaojing_offline.png"/>
              <!-- <img class="device" v-if="data.deviceDel === '2'" src="@/assets/kongkai2.svg"/> -->
              <!-- <i v-if="data.deviceDel === '2'" style="font-size: 14px;color: red;margin-right: 5px;" class="el-icon-circle-close"></i> -->
              <span v-if="data.deviceType === '0'">基站内空开</span>
              <span v-else-if="data.deviceType === '1'">基站外空开</span>
              <span v-else>{{data.deptName}}</span>
              <img
                class="warning"
                :class="{ visible: !!data.msg }"
                src="@/assets/gaojing.png"
                alt=""
              />
            </div>
          </template>
        </el-tree>
      </custom-actions>
    </div>
    <router-view></router-view>

 </div>
</template>

<script>

export default {
  name: 'DeviceList',

  data () {
    return {
      loading: false,

      deptList: [],
      defaultProps: {
        children: 'childNode',
        label: 'deptName',
        value: 'deptId',
        isLeaf: function (data) {
          return data.isDevice === '1'
        }
      }

    }
  },
  computed: {
    permission () {
      return this.$store.state.permission
    }
  },
  created () {
    // this.getDept()
  },
  methods: {
    // 多选
    slelectTable (info) {
      console.log(info)
      this.checkedDataList[this.page] = info
      console.log('this.checkedDataList', this.checkedDataList)
    },
    handleNodeClick (data) {
      console.log('点击基站数据', data)
      if (data.isLeaf === '0') {
        const path = `/device/list/list/${data.deptId}/${data.parentDeptId}`
        path !== this.$route.path && this.$router.replace({ path: path })

        // this.$router.push({
        //   name: 'deviceListPage',
        //   query: {
        //     deptId: data.deptId,

        //     parentDeptId: data.parentDeptId
        //   }
        // })
      } else if (data.isLeaf === '1') {
        const path = `/device/list/detail/${data.deptId}/${data.parentDeptId}`
        path !== this.$route.path && this.$router.replace({ path: path })
        // this.$router.push({
        //   name: 'deviceDetailPage',
        //   query: {
        //     deptId: data.deptId,
        //     parentDeptId: data.parentDeptId
        //   }
        // })
      } else if (data.isDevice === '1') { // 设备节点
        const path = `/device/list/childdetail/${data.deptId}/${data.deviceType}`
        path !== this.$route.path && this.$router.replace({ path: path })
      }
    },
    getDept (node, resolve) {
      this.$axios.get(this.$apis.system.selectSysDeptTree, {
        params: {
          parentDeptId: node.data ? node.data.deptId : null
        }
      }).then(res => {
        console.log('返回的数据', res)
        resolve(res)
      })
    },
    onAdd () {
      this.$router.push({ name: 'deviceAdd' })
    }

  }
}
</script>

<style scoped lang="scss">
.el-input,
.el-select,
.el-cascader {
  width: 250px;
}
.my-table {
  margin-top: 15px;
}

.el-tree {
  margin: -10px;
}

.org-item {
  display: flex;
  align-items: center;

  .device {
    width: 14px;
    margin-right: 5px;
  }

  .warning {
    width: 14px;
    margin-left: 3px;
    visibility: hidden;

    &.visible {
      visibility: visible;
    }
  }

  span {
    line-height: 15px;
    font-size: 13px;
    text-decoration: underline;
  }
}

.contrainer {
  display: flex;
  .contrainer-left {
    width: 260px;
    margin-right: 15px;
    min-height: calc(100vh - 90px);

    .card {
      height: 100%;
    }
  }
  .contrainer-right {
    flex: 1;
    min-width: 600px;
    min-height: calc(100vh - 90px);
  }
}
// ::v-deep .el-tree {
//   font-size: 14px;
//   .el-tree-node__content {
//     font-size: 14px;
//     height: 34px;
//     line-height: 34px;
//   }
//   .el-tree-node__children {
//     .el-tree-node .el-tree-node__content {
//       font-size: 14px;
//       margin: 4px 0;
//       height: 34px;
//       line-height: 34px;
//       padding-right: 18px;
//       overflow: hidden;
//       text-overflow: ellipsis;
//       white-space: nowrap;
//       max-width: 350px;
//     }
//   }
// }
.form-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .label {
    width: 100px;
    font-size: 14px;
    color: #999;
    text-align: right;
  }
  .value {
    flex: 1;
    font-size: 14px;
    color: #000;
    margin-left: 10px;
  }
}
.title-form {
  font-size: 14px;
  font-weight: 600;
  margin: 30px 0 20px 0;
  padding-left: 14px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 14px;
    background: #007165;
  }
}
.child-form {
  ::v-deep .el-form-item {
    margin-bottom: 0 !important;
  }
}
.close-style {
  width: 36px;
  height: 8px;
  display: inline-block;
  overflow: visible;
  background-color: #f40909;
  border-radius: 16px;
  box-shadow: 0px 0px 6px rgba(255, 0, 0, 0.8);
}
.open-style {
  width: 36px;
  height: 8px;
  display: inline-block;
  overflow: visible;
  background-color: green;
  border-radius: 16px;
  box-shadow: 0px 0px 6px rgba(0, 255, 0, 0.8);
}
.on-line {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: green;
}
.off-line {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: red;
}

.row {
  display: flex;
  margin-bottom: 20px;
  font-size: 14px;
  .label {
    width: 150px;
    text-align: right;
  }
  .value {
    word-break: break-all;
    margin-left: 14px;
  }
}
.title-box {
  font-size: 14px;
  margin: 10px 0 30px 0;
  padding-left: 10px;
  position: relative;
  &.title-box2 {
    margin-top: 40px;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 14px;
    background: #007165;
  }
}
.content-table {
  width: 860px;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-collapse: collapse;

  tr {
    td {
      font-size: 14px;
      line-height: 38px;
      display: inline-block;
      width: 25%;
      height: 38px;
      border-right: 1px solid #ccc;
      border-top: 1px solid #ccc;
      text-align: left;
      padding: 0 10px;
      &:nth-child(2n-1) {
        text-align: right;
      }

      .td-value {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.close-style {
  width: 36px;
  height: 8px;
  display: inline-block;
  overflow: visible;
  background-color: #f40909;
  border-radius: 16px;
  box-shadow: 0px 0px 6px rgba(255, 0, 0, 0.8);
}
.open-style {
  width: 36px;
  height: 8px;
  display: inline-block;
  overflow: visible;
  background-color: green;
  border-radius: 16px;
  box-shadow: 0px 0px 6px rgba(0, 255, 0, 0.8);
}
.table-bx {
  display: flex;
  width: 100%;
  .content-table {
    width: 420px;
    tr {
      td {
        width: 50%;
      }
    }
  }
  .left {
    margin-right: 30px;
  }
}
</style>
